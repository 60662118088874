import React from "react";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
//import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const ProductsMedicalDeviceHal = ({ data, location }) => {
  const intl = useIntl();

  return (
    <Layout location={location}>
      <Seo
        title={
          intl.locale === "en"
            ? `HAL senses bio-electrical signals and complete intended motion of wearers`
            : `HAL erkennt bioelektrische Signale und vollendet die Bewegung des Trägers`
        }
        description={
          intl.locale === "en"
            ? `HAL’s innovative interactive bio-feedback system (iBF technology)  promotes functional improvement, acquisition and brain regenerations, in patients with a wide range of neurological conditions.`
            : `HAL innovatives Bio-Feedback System (iBF Technologie) fördert funktionelle Verbesserungen und Regeneration des Gehirns, bei einer Vielzahl neurologischer Probleme.`
        }
      />
      <section className="sect sect_TreatmentCvd_first sect-bg-white pt-0">
        <div className="main_back"></div>
        <div className="main_copy">
          <h1 className="h1">
            {intl.locale === "en"
              ? `Medical Cybernics Technology`
              : `Medizinische Cybernics Technologie`}
          </h1>
          <p className="message-label-bold txt txt-rot">
            {intl.locale === "en"
              ? `Motion principle of HAL`
              : `Bewegungsprinzip des HALs`}
          </p>
          <p>
            {intl.locale === "en"
              ? `When a person tries to move, signals sent from brain to the
  muscles trickle onto the surface of the skin as very faint
  bio-electrical signals, and HAL is capable of detecting these
  signals and consolidates various information to complete the
  motion of patients. As a result, a neurological loop of afferent
  and efferent neurons is established between brain and muscle
  through HAL.`
              : `Wenn eine Person versucht sich zu bewegen, werden neuronale Signale vom Gehirn über das Rückenmark an den zu bewegenden Muskel gesendet. Diese können, als bioelektrische Signale auf der Hautoberfläche, vom HAL erkannt und verarbeitet werden, um die beabsichtige Bewegung zu vollenden. Als Ergebnis wird die neurologische Schleife von afferenten und efferenten Neuronen zwischen Gehirn und Muskel durch den HAL vollendet.`}
          </p>
        </div>
        <div className="main_img">
          <StaticImage
            src="../../../images/products/motion-principle-headerimage.png"
            alt="ImgTreatmentCatch"
            className="ImgHeader"
          />
        </div>
      </section>

      <section className="sect  bg-white p-0">
        <div className="sect_ProductMd__catch1___blc2 pt-6 bg-bls">
          <div className="container flex-row pt-9 mt-9 pt-sm-4 pb-sm-4 mt-sm-0">
            <div className="flex-column-8 pr-9">
              <h2 className="h3">
                {intl.locale === "en"
                  ? `How HAL Works`
                  : `Wie HAL funktioniert`}
              </h2>
              {intl.locale === "en" ? (
                <p>
                  Our intention to move triggers bio-electrical signals (BES)
                  from the brain that communicate with the muscles that make
                  locomotion possible.
                  <br />
                  Such signals from paralyzed patients may be too weak to
                  trigger proper movement. However, HAL can sensor faint BES
                  that trickle onto the skin, process it, and simultaneously
                  assist one’s desired movement with motors located on each hip
                  and knee joint.
                  <br />
                  More importantly, the brain receives feedback from the target
                  muscle through the sensory system and learns how to move legs
                  correctly after repetition of dedicated HAL gait training.
                </p>
              ) : (
                <p>
                  Unsere Absicht, uns zu bewegen, löst bioelektrische Signale
                  (BES) in unserem Gehirn aus, die mit den für die Bewegung
                  nötigen Muskeln kommunizieren und die Fortbewegung
                  ermöglichen.
                  <br />
                  Bei gelähmten Patienten können diese Signale zu schwach sein,
                  um eine richtige Bewegung auszulösen. HAL kann jedoch schwache
                  BES, die durch die Haut gelangen, sensorisch erfassen,
                  verarbeiten und gleichzeitig mit Motoren, die sich an jedem
                  Hüft- und Kniegelenk befinden, die gewünschte Bewegung
                  unterstützen.
                  <br />
                  Noch wichtiger ist, dass das Gehirn über das sensorische
                  System eine Rückmeldung vom Zielmuskel erhält und lernt, wie
                  man die Beine nach einem wiederholtem, speziellen
                  HAL-Gangtrainings richtig bewegt.
                </p>
              )}
            </div>
            <div className="flex-column-4">
              {intl.locale === "en" ? (
                <ul className="list-md list_md_notop list_md_white">
                  <li className="fsi">
                    Support, assist, enhance, and regenerate
                  </li>
                  <li className="fsi">
                    Interactive bio-feedback loop is established
                  </li>
                  <li className="fsi">
                    Neurologically controlled by wears' intention
                  </li>
                </ul>
              ) : (
                <ul className="list-md list_md_notop list_md_white">
                  <li className="fsi">
                    Unterstützen, Helfen, Verbessern, Regenerieren
                  </li>
                  <li className="fsi">
                    Etablierung der interaktiven Biofeedback-Schleife
                  </li>
                  <li className="fsi">
                    Durch die Intention des Trägers neurologisch kontrolliert
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="sect sect_ProductMd__explanation bg-white">
        <div className="container py-8 my-8 flex-row pt-sm-4 pb-sm-4 mt-sm-0">
          <div className="flex-column-6 p-2">
            <ul>
              <li className="flex-row">
                <p className="number-circle">
                  <span>1</span>
                </p>
                <div className="flex-column-auto pl-4 mt-3">
                  <p className="title h4 font-weight-semibold">
                    {intl.locale === "en" ? `Send` : `Senden`}
                  </p>
                  <p className="description">
                    {intl.locale === "en"
                      ? `The brain sends command signals. When a person tries to move
  their body, the brain transmits necessary signals through
  the nerves to the muscles.`
                      : `Das Gehirn sendet Befehlssignale. Wenn eine Person versucht, ihren Körper zu bewegen, sendet das Gehirn die erforderlichen Signale über die Nerven an die Muskeln.`}
                  </p>
                </div>
              </li>
              <li className="flex-row">
                <p className="number-circle">
                  <span>2</span>
                </p>
                <div className="flex-column-auto pl-4 mt-3">
                  <p className="title h4 font-weight-semibold">
                    {intl.locale === "en" ? `Receive` : `Empfangen`}
                  </p>
                  <p className="description">
                    {intl.locale === "en"
                      ? `The muscles receive the command signals. Each muscle
  contracts to move its corresponding joint when it receives
  the appropriate command signal sent from the brain through
  the nerves. With a spinal cord injury, this pathway is
  disrupted and the signal is too weak to generate sufficient
  force.`
                      : `Die Muskeln empfangen die Befehlssignale. Jeder Muskel, der ein entsprechendes Befehlssignal über die Nerven vom Gehirn erhält, kontrahiert, um das entsprechende Gelenk zu bewegen. Bei einem Schlaganfall oder einer Rückenmarksverletzung ist dieser Weg beeinträchtigt und das Signal ist zu schwach, um eine ausreichende Kraft zu erzeugen.`}
                  </p>
                </div>
              </li>
              <li className="flex-row">
                <p className="number-circle">
                  <span>3</span>
                </p>
                <div className="flex-column-auto pl-4 mt-3">
                  <p className="title h4 font-weight-semibold">
                    {intl.locale === "en" ? `Read` : `Lesen`}
                  </p>
                  <p className="description">
                    {intl.locale === "en"
                      ? `HAL reads the signals. Signals sent to the muscles by the
  brain trickle onto the skin surface as very faint
  bio-electrical signals (BES). HAL is capable of detecting
  these signals and consolidating this information with other
  information collected by the device to recognize and
  complete the intended motion of patients.`
                      : `HAL liest die Signale. Die vom Gehirn an die Muskeln gesendeten Signale gelangen als sehr schwache bioelektrische Signale (BES) auf die Hautoberfläche. HAL ist in der Lage, diese Signale zu erkennen und diese Informationen mit anderen vom Gerät gesammelten Informationen zusammenzuführen. Die beabsichtigte Bewegung des Patienten wird erkannt und vervollständigt.`}
                  </p>
                </div>
              </li>
              <li className="flex-row">
                <p className="number-circle">
                  <span>4</span>
                </p>
                <div className="flex-column-auto pl-4 mt-3">
                  <p className="title h4 font-weight-semibold">
                    {intl.locale === "en" ? `Move` : `Bewegen`}
                  </p>
                  <p className="description">
                    {intl.locale === "en"
                      ? `HAL moves as the wearer intends. HAL controls the power
  units at each joint independently based on these BES and
  allows the wearer to perform the desired movements with
  their voluntary commands.`
                      : `HAL bewegt sich so, wie es der Träger beabsichtigt. HAL steuert die Antriebseinheiten an jedem Gelenk unabhängig voneinander auf der Grundlage dieses BES und ermöglicht es dem Träger, die gewünschten Bewegungen mit seinen freiwilligen Befehlen auszuführen.`}
                  </p>
                </div>
              </li>
              <li className="flex-row">
                <p className="number-circle">
                  <span>5</span>
                </p>
                <div className="flex-column-auto pl-4 mt-3">
                  <p className="title h4 font-weight-semibold">
                    {intl.locale === "en"
                      ? `Feedback-loop`
                      : `Feedback-Schleife`}
                  </p>
                  <p className="description">
                    {intl.locale === "en"
                      ? `Information of movement is sent back to the brain. When HAL
  has appropriately assisted the intended movement, the
  feeling is fed back to the brain. As a result, a
  neurological loop of afferent and efferent neurons is
  established between the brain and the muscles through HAL.
  The use of this network of signals to induce neuroplasticity
  is what we call “interactive bio-feedback”. Active use of
  these neural pathways for voluntary movement with physical
  feedback to the brain leads to an improved ability for the
  wearer to walk on their own.`
                      : `Die Informationen über die Bewegung werden an das Gehirn zurückgesendet. Wenn der HAL die beabsichtigte Bewegung angemessen unterstützt hat, sendet der bewegte Muskel ein Feedback über das sensorische System zurück an das Gehirn. Auf diese Weise entsteht durch HAL eine neurologische Schleife aus afferenten und efferenten Neuronen zwischen dem Gehirn und den Muskeln. Die Nutzung dieses Netzwerks von Signalen zur Induzierung von Neuroplastizität nennen wir "interaktives Biofeedback". Die aktive Nutzung dieser neuronalen Bahnen für willkürliche Bewegungen mit physischem Feedback an das Gehirn führt zu einer verbesserten Fähigkeit des Trägers, selbständig zu gehen.`}
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className="flex-column-6">
            <StaticImage
              src="../../../images/products/motion-principle-grafic-full@2x.png"
              className="sect_Product__explanation___img1"
            />
          </div>
        </div>
      </section>
      <section className="sect sect-qa bg-rot">
        <div className="container">
          <p className="h2 mb-5">
            {intl.locale === "en"
              ? `Learn more about our products`
              : `Erfahren Sie mehr über unsere Produkte`}
          </p>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>HAL Lower Limb Type</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Think, Send, Read, Move—HAL is the World first neurologically
  controlled wearable Cyborg which puts your intentions into
  action. HAL improves locomotor activity in stroke, spinal cord
  injury and other neuromuscular disease patients.`
                    : `Denken, Senden, Lesen, Bewegen - HAL ist der weltweit erste neurologisch kontrollierte tragbare Cyborg, der Ihre Absichten in Aktionen umsetzt. HAL verbessert die Bewegungsfähigkeit nach Schlaganfällen, Rückenmarksverletzungen und anderen neuromuskulären Erkrankungen.`}
                </p>
                <Link
                  to="/products/medical-device/hal-limb/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>HAL Single Joint</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `HAL Single Joint is a portable and lightweight unit which enables intensive and repetitive training of specific joints such as elbow, knee, and ankle.`
                    : `HAL Single Joint ist ein portables und leichtes Gerät, das ein intensives und wiederholtes Training eines speziellen Gelenkes, wie zum Beispiel Ellbogen, Knie und Sprunggelenk, ermöglicht.`}
                </p>
                <Link
                  to="/products/medical-device/hal-joint/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>HAL Lumbar Type</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Improving a quality of life by ensuring your well-being, reducing fatigue in the work environment, regaining your strength and mobility, and expanding your capability.`
                    : `Verbesserung der Lebensqualität durch Sicherstellung Ihres Wohlbefindens, Reduzierung der Ermüdung im Arbeitsumfeld, Wiedererlangen Ihrer Kraft und Mobilität, sowie Ausbau Ihrer Leistungsfähigkeit.`}
                </p>
                <Link
                  to="/products/non-medical-device/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Cleaning Robot`
                    : `Reinigungsroboter`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Keeping large facilities such as airport, shopping malls,
  hotels, and conference centers clean has never been easier.
  Find out how our cleaning and disinfection robot keeps your
  space tidy without the need for human operation.`
                    : `Es war nie einfacher große Gebäude wie Flughäfen, Einkaufszentren, Hotels und Konferenzzentren sauber zu halten. Finden Sie heraus wie unser Reinigungsroboter Ihre Räume ohne menschliche Unterstützung sauber hält.`}
                </p>
                <div>
                  <Link to="/products/cleaning-robot/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>CYIN</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Ingenious interfaces for people including those suffer from
  progressive neurodegenerative disease. CYIN utilizes
  biological information to help communicate with ease, despite
  all their limitations.`
                    : `Geniale Schnittstelle für Menschen, einschließlich derer die an progressiven neurodegenerativen Erkrankungen leiden. CYIN nutzt biologische Informationen um die Kommunikation, unabhängig all Ihrer Einschränkungen, zu vereinfachen.`}
                </p>
                <div>
                  <Link to="/products/communication/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>Acoustic X</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Acoustic X is photoacoustic imaging technology that helps
  detect melanin, lipid, collages, water, and contrast agents
  specifically designed to locate various biomarkers.`
                    : `Acoustic X ist eine photoakustische Bildgebungstechnologie, die Melanin, Fette, Kollagen und Kontrastmittel zur Lokalisation bestimmter Biomarker, erkennt.`}
                </p>
                <div>
                  <Link to="/products/imaging/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>
      <section className="sect sect_Product__footer p-0">
        <div className="container py-8 my-8 flex-row">
          <div className="flex-column-6">
            <h2 className="h3">
              {intl.locale === "en"
                ? `Do you want to know more about Cybernics Technology?`
                : `Wollen Sie mehr über die Cybernics Technologie erfahren?`}
            </h2>
            <p>
              {intl.locale === "en"
                ? `Cybernics is a coined term by Prof. Yoshiyuki Sankai, founder of Cyberdyne. Cybernics is an new cross-disciplinary academic field whose core disciplines are related to human, robotics and information systems.`
                : `Cybernics ist ein von Prof. Yoshiyuki Sankai, Gründer von Cyberdyne, geprägter Begriff. Cybernics ist ein neues interdisziplinäres akademisches Feld. Die Kernbereiche sind auf die Menschen, Roboter und Informationssysteme ausgelegt.`}
            </p>
            <div>
              <Link to="/company/cybernics/" className="link_button">
                {intl.locale === "en" ? `About Cybernics` : `Mehr zu Cybernics`}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ProductsMedicalDeviceHal;
